<template>
    <v-footer app inset>
        <span class="jt-footer-note">
            &copy; {{ new Date().getFullYear() }} All rights reserved.
        </span>
        <v-spacer />
        <span class="jt-footer-message">"Astra Virtus PowerTool v3"</span>
    </v-footer>
</template>

<script>
export default {
    components: {},
    data: () => ({}),
    name: "FooterNav",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
